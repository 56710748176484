













import { Component, Vue, Watch } from 'vue-property-decorator';

type TOptions = {
  width: number;
  zIndex: number;
};

const DEFAULT_OPTIONS: TOptions = {
  width: 400,
  zIndex: 99,
};
@Component
export default class ConfirmDialog extends Vue {
  public isOpen = false;
  public resolve: ((value: boolean) => void) | null = null;
  public reject: ((value: boolean) => void) | null = null;
  public message = '';
  public options: TOptions = DEFAULT_OPTIONS;

  open(message: string, options = DEFAULT_OPTIONS) {
    this.isOpen = true;
    this.message = message;
    this.options = Object.assign(this.options, options);
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  public agree() {
    this.resolvePromise(true);
    this.isOpen = false;
  }

  public cancel() {
    this.resolvePromise(false);
    this.isOpen = false;
  }

  private resolvePromise(value: boolean) {
    this.resolve?.(value);
    this.resolve = null;
  }

  @Watch('isOpen')
  public onIsOpenChange(value: boolean) {
    if (!value && this.resolve) this.resolvePromise(false);
  }
}
